import React, { useEffect, useState } from "react";
import DefaultLayout from "../../layouts/DefaultLayout";
import { getData } from "../../services/HttpServices";
import { setPageTitle } from "../../store/resourceSlice";
import { setBreadcrumbs } from "../../store/breadcrumbSlice";
import { useDispatch } from "react-redux";
import Activestockpopup from "../inventory/activestockpopup";
import Fruitmasterpopup from "../inventory/fruitmasterpopup";
import Runratepopup from "../inventory/runratepopup";
import { Link } from "react-router-dom";
import ReactECharts from "echarts-for-react";
import Salegridpopup from "../inventory/salegridpopup";
//import DatePicker from "react-datepicker";
const Dashboard = () => {
    const dispatch = useDispatch();

    const [supplierProducts, setSupplierProducts] = useState([]);
    const [upcomingsaleDeliveries, setUpcomingsaleDeliveries] = useState([]);
    const [upcomingpurchaseDeliveries, setUpcomingpurchaseDeliveries] = useState([]);
    const [show, setShow] = useState(false);
    const [runrate, setRunrate] = useState(false);
    const [runRateData, setRunRateData] = useState([]);
    const [runDate, setRunDate] = useState(new Date());
    const [totalRunrate, setTotalRunRate] = useState(10);

    const [saleDate, setSaleDate] = useState(null);
    const [useByDate, setUseByDate] = useState(null);
    const [salegrid, setSaleGrid] = useState(false);
    const [saleGridData, setSaleGridData] = useState([]);
    const [saleGridLocationData, setSaleGridLocationData] = useState([]);
    const [saleProductTypes, setSaleProductTypesData] = useState([]);

    const [fruit, setFruit] = useState(false);
    const [inventoryData, setInventoryData] = useState([]);
    const [activePrintData, setActivePrintData] = useState([]);
    const [inventoryCounts, setInventoryCounts] = useState(0);
    const [totalpallets, setTotalpallets] = useState(0);
    const [locationpallets, setLocationpallets] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [categories, setCategories] = useState([]);
    const [upcomingdebulk, setUpcomingdebulk] = useState([]);
    const [counts, setCounts] = useState({
        supplier_counts: 0,
        sale_order_counts: 0,
        sale_order_completed_counts: 0,
        purchase_order_completed_counts: 0,
        weekly_sale_percentage: 0,
        weekly_sale_state: 0,
        purchase_order_counts: 0,
        weekly_sale_po_percentage: 0,
        weekly_sale_po_state: 0,
        sale_order_pending: 0,
        sale_order_qa_approve: 0,
        sale_order_qa_completed: 0,
        purchase_order_pending: 0,
        purchase_order_qa_approve: 0,
        sale_order_status_counts: [],
        purchase_order_status_counts: [],
        purchase_order_sm_approval: 0,
    });
    const [weeklysale, setWeeklysale] = useState([]);
    const [pageNo, setPageNo] = useState(1);
    const [isNext, setIsNext] = useState(false);
    const [limit, setLimit] = useState(10);
    const [upcomingdebulkEndDate, setUpcomingdebulkEndDate] = useState(null);
    const upcomingDeBulkData = () => {
        getData("/dashboard/upcomingdebulk").then((response) => {
            if (response.status === 200) {
                let responseData = response.data;
                setUpcomingdebulk(responseData.data.records);
                setUpcomingdebulkEndDate(responseData.data.endDate);
            }
        });
    };
    useEffect(() => {
        // setEndDate(endDate.setDate(endDate.getDate() + 5))
        setRunDate(new Date());
        const getDashboardData = () => {
            getData("/dashboard").then((response) => {
                if (response.status === 200) {
                    let responseData = response.data;
                    setSupplierProducts(responseData.data.supplierproducts);
                    setUpcomingsaleDeliveries(responseData.data.upcomingsaledeliveries);
                    setUpcomingpurchaseDeliveries(
                        responseData.data.upcomingpurchasedeliveries
                    );
                    setCounts(responseData.data.counts);
                    setWeeklysale(responseData.data.weeklysale);
                    setCategories(responseData.data.categories);
                }
            });
        };
        getDashboardData();
        upcomingDeBulkData();

        dispatch(setPageTitle("Dashboard"));
        dispatch(
            setBreadcrumbs([{
                name: "Dashboard",
                link: "/dashboard",
            }, ])
        );
        // eslint-disable-next-line
    }, [dispatch]);

    const getInventoryData = (pageno, category = '',lastUpdate=false) => {
        if(lastUpdate)
          lastUpdate = 1
        else
          lastUpdate = 0
        setIsLoading(true);
        category = category ? category.map(c => c.value).join('|'): 0

        getData("/activestock?page=" + pageno+"&category=" + category+"&lastupdate=" + lastUpdate).then((response) => {
            if (response.status === 200) {
                let responseData = response.data;
                setInventoryData(responseData.data.products);
                setActivePrintData(responseData.data.printdata);
                setInventoryCounts(responseData.data.counts);
                setPageNo(responseData.data.page_no);
                setIsNext(responseData.data.is_next);
                setLimit(responseData.data.limit);
            }
            setIsLoading(false);
        });
    };
    const getRunrateData = (startDate = "", category = []) => {
        setIsLoading(true);
        getData("/runrates?date=" + startDate + "&category=" + category).then(
            (response) => {
                if (response.status === 200) {
                    let responseData = response.data;
                    setRunRateData(responseData.data.categories);
                    // setRunDate(responseData.data.formattedDate);
                    setTotalRunRate(responseData.data.total);
                }
            }
        );
        setIsLoading(false);
    };
    const getSaleGridData = (startDate = "") => {
        setIsLoading(true);
        getData("/productionplan/salesgrids?date=" + startDate).then((response) => {
            if (response.status === 200) {
                let responseData = response.data;
                setSaleGridData(responseData.data.items);
                setSaleGridLocationData(responseData.data.locations);
                setSaleProductTypesData(responseData.data.producttypes);
                setSaleDate(responseData.data.currentDate);
                setUseByDate(responseData.data.usedby);
                setTotalpallets(responseData.data.totalpallets);
                setLocationpallets(responseData.data.locationpallets);
            }
        });
        setIsLoading(false);
    };

    const zipSaleGridData = (startDate = "") => {
        setIsLoading(true);
        getData("/salegrid/zippdf?date=" + startDate).then((response) => {
            if (response.status === 200) {
                let responseData = response.data;
                DownloadZip(responseData.data.folder);
                return;
            }
        });
        setIsLoading(false);
    };
    const DownloadZip = (fileURL) => {
        window.open(
            process.env.REACT_APP_IMAGE_URL + fileURL + "&type=zip",
            "_blank",
            "noreferrer"
        );
        return;
    };
    const showActiveStock = () => {
        setShow(true);
        getInventoryData(pageNo);
    };
    const hideActiveStock = () => {
        setShow(false);
    };
    const showFruitMaster = () => {
        setFruit(true);
    };
    const hideMasterActiveStock = () => {
        setFruit(false);
    };
    const showRunrate = () => {
        let date = runDate.toLocaleDateString("en-UK");

        getRunrateData(date);
        setRunrate(true);
    };
    const hideRunrate = () => {
        setRunDate(new Date());
        setRunrate(false);
    };
    const showSaleGrid = () => {
        getSaleGridData();
        setSaleGrid(true);
    };
    const hideSaleGrid = () => {
        setSaleGrid(false);
    };
    const paginationUpdate = (pageno, category = '',lastUpdate=false) => {
        setPageNo(pageno);
        getInventoryData(pageno,category,lastUpdate);
    };
    const runRateDatehandle = (date) => {
        setRunDate(date);
    };

    return (
        <DefaultLayout>
      <section className="section dashboard">
        <div className="row">
          <div className="col-lg-12">
            <div className="card info-card sales-card">
              <div className="card-col">
                <span className="card-title">Quick Links</span>
                <div className="btn-group btn-buttons">
         
                  <button
                    onClick={showActiveStock}
                    className="btn btn-primary view-btn"
                  >
                    Active Stock
                  </button>{" "}
           
                  <Link
                    to={"/daliyproductionrecord"}
                    className="btn btn-primary view-btn"
                  >
                    Daily Production Record
                  </Link>
          
           
                  <Link
                    to={"/daliyproductionplan"}
                    className="btn btn-primary view-btn"
                  >
                    Daily Production Plan
                  </Link>
      
                  <button
                    onClick={showFruitMaster}
                    className="btn btn-primary view-btn"
                  >
                    Fruit Master
                  </button>{" "}
              
                  <button
                    onClick={showRunrate}
                    className="btn btn-primary view-btn mx-2"
                  >
                    Run Rate
                  </button>{" "}
          
                  <button
                    onClick={showSaleGrid}
                    className="btn btn-primary view-btn mx-2"
                  >
                    Sale Grid
                  </button>{" "}
          
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row dashboard-counter-div">
          <div className="col-12 col-md-6">
            <div className="card info-card sales-card">
              <div className="card-body">
                <h5 className="card-title">Sales Orders</h5>
                <div className="row">
                <ReactECharts
                id={"graph"}
                option={{
                  tooltip: {
                    trigger: "item",
                    formatter: "{b} : {c}",
                  },
                  toolbox: {
                    show: true,
                    feature: {
                      magicType: { show: true, type: ["line", "bar"] },
                      restore: { show: true },
                      saveAsImage: { show: true },
                    },
                  },
                  calculable: true,
                  xAxis: {
                    type: "category",
                    data: counts.sale_order_status_counts.labels,
                    axisLabel: {
                      interval: 0,
                      rotate: 30 //If the label names are too long you can manage this by rotating the label.
                    }
                  },
                  yAxis: {
                    type: "value",
                  },
                  series: [
                    {
                      data: counts.sale_order_status_counts.values,
                      type: "bar",
                    },
                  ],
                  graph: {
                    color: counts.sale_order_status_counts.colors
                }
                }}
                notMerge={true}
                lazyUpdate={true}
              />
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="card info-card sales-card">
              <div className="card-body">
                <h5 className="card-title">Purchase Orders</h5>
                <div className="row">
                <ReactECharts
                id={"pograph"}
                option={{
                  tooltip: {
                    trigger: "item",
                    formatter: "{b} : {c}",
                  },
                  toolbox: {
                    show: true,
                    feature: {
                      magicType: { show: true, type: ["line", "bar"] },
                      restore: { show: true },
                      saveAsImage: { show: true },
                    },
                  },
                  calculable: true,
                  xAxis: {
                    type: "category",
                    data: counts.purchase_order_status_counts.labels,
                    axisLabel: {
                      interval: 0,
                      rotate: 30 //If the label names are too long you can manage this by rotating the label.
                    }
                  },
                  yAxis: {
                    type: "value",
                  },
                  series: [
                    {
                      data: counts.purchase_order_status_counts.values,
                      type: "bar",
                    },
                  ],
                  graph: {
                    color: counts.purchase_order_status_counts.colors
                }
                }}
                notMerge={true}
                lazyUpdate={true}
              />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <div className="card info-card sales-card">
              <div className="card-col">
                <span className="card-title">
                  Upcoming Requirements  {upcomingdebulkEndDate &&  "Upto :" + upcomingdebulkEndDate} (Sale orders)
                </span>
               
                <span className="float-end">
                {/* <DatePicker
                     className="form-control"
                      selected={startDate}
                      onChange={onDateRangeChange}
                      startDate={startDate}
                      endDate={endDate}
                      dateFormat="dd/MM/yyyy"
                      placeholderText="Click to select a date"
                      monthsShown={2}
                      selectsRange
                      isClearable
              /> */}
                </span>
              </div>
              <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr className="table-title">
                    <th>Product ID</th>
                    <th>Product Code</th>
                    <th>Description</th>
                    <th>Recipe</th>
                    <th>Delivery Date</th>
                    <th>Current Quantity</th>
                    <th>Required Quantity</th>
                  </tr>
                </thead>
                <tbody>
                  {upcomingdebulk.length > 0 ?
                    upcomingdebulk.map((debulk, index) => {
                      return (
                        <tr className={debulk.current_quantity <= debulk.quantity ?'bg-danger text-white':''} key={index}>
                          <td>
                              {debulk.product_id}
                          </td>
                          <td>{debulk.code}</td>
                          <td>
                            {debulk.description || 'N/A'}
                          </td>
                          <td>{debulk.title}</td>
                          <td>{debulk.delivery_date}</td>
                          <td>{debulk.current_quantity}</td>
                          <td>{debulk.quantity}</td>
                        </tr>
                      );
                    }) : <tr>
                      <td colSpan={7} className="alert alert-warning text-center"> No Upcoming Orders In Row.</td>
                    </tr>
                  
                  }
                </tbody>
              </table>
            </div>
          </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <div className="card info-card sales-card">
              <div className="card-col">
                <span className="card-title">
                  Upcoming Deliveries (Purchase orders)
                </span>
                <span className="float-end">
                  <Link
                    to="/purchaseorders"
                    className="btn btn-primary view-btn"
                  >
                    View All
                  </Link>{" "}
                </span>
              </div>
                       <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr className="table-title">
                    <th>Order Number</th>
                    <th>Supplier</th>
                    <th>Price</th>
                    <th>Delivery Date</th>
                  </tr>
                </thead>
                <tbody>
                  {upcomingpurchaseDeliveries.length > 0 &&
                    upcomingpurchaseDeliveries.map((order, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            <Link to={`/purchaseorders/details/` + order.id}>
                              {order.sage_code}
                            </Link>
                          </td>
                          <td>{order.supplier_name}</td>
                          <td>
                            <span
                              dangerouslySetInnerHTML={{
                                __html: order.currency_symbol,
                              }}
                            />
                            {order.total}
                          </td>
                          <td>{order.delivery_date}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="card info-card sales-card">
              <div className="card-col">
                <span className="card-title">
                  Upcoming Deliveries (Sales orders)
                </span>
                <span className="float-end">
                  <Link to="/orders" className="btn btn-primary view-btn">
                    View All
                  </Link>{" "}
                </span>
              </div>
                       <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr className="table-title">
                    <th>Order Number</th>
                    <th>Customer</th>
                    <th>Quantity</th>
                    <th>Delivery Date</th>
                  </tr>
                </thead>
                <tbody>
                  {upcomingsaleDeliveries.length > 0 &&
                    upcomingsaleDeliveries.map((order, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            <Link to={`/orders/details/` + order.id}>
                              {order.sage_code}
                            </Link>
                          </td>
                          <td>{order.client_contact_name}</td>
                          <td>{order.total_quanitity}</td>
                          <td>{order.delivery_date}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <div className="card info-card sales-card">
              <div className="card-col">
                <span className="card-title">Last Goods received</span>
                <span className="float-end">
                  <Link to="/inventory" className="btn btn-primary view-btn">
                    View All
                  </Link>{" "}
                </span>
              </div>
                       <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr className="table-title">
                    <th>Product</th>
                    <th>Price</th>
                    <th>Quantity</th>
                    <th>Collection Date</th>
                  </tr>
                </thead>
                <tbody>
                  {supplierProducts.length > 0 &&
                    supplierProducts.map((product, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            {product.description?  product.description.length < 15
                              ? product.description
                              : product.description.substring(0, 15) + "..." : 'N/A'}
                          </td>
                          <td>£ {product.standard_price}</td>
                          <td>{product.quantity}</td>
                          <td>{product.order_date}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="card">
              <div className="order-block">
                <div className="row">
                  <div className="col-md-6 p-4">
                    <div className="order-list">
                      <span className="order-icons">
                        {" "}
                        <i className="bi bi-building-fill-check"></i>{" "}
                      </span>
                      <span>
                        <b>{counts.sale_order_counts}</b>Sale Orders
                      </span>
                      {counts.weekly_sale_state && (
                        <p className="increase-arrow">
                          {" "}
                          <i className="bi bi-arrow-up"></i>{" "}
                          <span>
                            {counts.weekly_sale_percentage.toFixed(2)} %{" "}
                          </span>{" "}
                          Weekly
                        </p>
                      )}
                      {!counts.weekly_sale_state && (
                        <p className="decrease-arrow">
                          {" "}
                          <i className="bi bi-arrow-down"></i>{" "}
                          <span>
                            {counts.weekly_sale_percentage.toFixed(2)} %{" "}
                          </span>{" "}
                          Weekly
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6 p-4">
                    <div className="order-list">
                      <span className="order-icons">
                        {" "}
                        <i className="bi bi-building-fill-check"></i>{" "}
                      </span>
                      <span>
                        <b>{counts.purchase_order_counts}</b>Purchased Orders
                      </span>
                      {counts.weekly_sale_po_state && (
                        <p className="increase-arrow">
                          {" "}
                          <i className="bi bi-arrow-up"></i>{" "}
                          <span>
                            {counts.weekly_sale_po_percentage.toFixed(2)} %{" "}
                          </span>{" "}
                          Weekly
                        </p>
                      )}
                      {!counts.weekly_sale_po_state && (
                        <p className="decrease-arrow">
                          {" "}
                          <i className="bi bi-arrow-down"></i>{" "}
                          <span>
                            {counts.weekly_sale_po_percentage.toFixed(2)} %{" "}
                          </span>{" "}
                          Weekly
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="col-md-6 p-4">
                    <div className="order-list">
                      <span className="order-icons">
                        {" "}
                        <i className="bi bi-basket-fill"></i>{" "}
                      </span>
                      <span>
                        <b>
                          {counts.sale_order_completed_counts &&
                            counts.sale_order_completed_counts}
                        </b>
                        SO Completed
                      </span>
                    </div>
                  </div>
                  <div className="col-md-6 p-4">
                    <div className="order-list">
                      <span className="order-icons">
                        {" "}
                        <i className="bi bi-truck"></i>{" "}
                      </span>
                      <span>
                        <b>
                          {counts.purchase_order_completed_counts &&
                            counts.purchase_order_completed_counts}
                        </b>
                        PO Completed
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="card">
              <div className="card-col">
                <span className="card-title">Sale Orders (Weekly)</span>
              </div>
              <div className="row">
                <ReactECharts
                  id={"graph"}
                  option={{
                    tooltip: {
                      trigger: "item",
                      formatter: "{b} : {c}",
                    },
                    toolbox: {
                      show: true,
                      feature: {
                        magicType: { show: true, type: ["line", "bar"] },
                        restore: { show: true },
                        saveAsImage: { show: true },
                      },
                    },
                    calculable: true,
                    xAxis: {
                      type: "category",
                      data: weeklysale.labels,
                    },
                    yAxis: {
                      type: "value",
                    },
                    series: [
                      {
                        data: weeklysale.values,
                        type: "bar",
                      },
                    ],
                  }}
                  notMerge={true}
                  lazyUpdate={true}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="card">
              <div className="card-col">
                <span className="card-title">Total Counters</span>
              </div>
              <div className="row">
                <ReactECharts
                  id={"pie"}
                  option={{
                    legend: {
                      top: "bottom",
                    },
                    tooltip: {
                      trigger: "item",
                      formatter: "{b} : {c}",
                    },
                    series: [
                      {
                        name: "Total Counters",
                        type: "pie",
                        radius: [10, 100],
                        center: ["50%", "50%"],
                        roseType: "area",
                        itemStyle: {
                          borderRadius: 8,
                        },
                        data: [
                          {
                            value: counts.sale_order_counts,
                            name: "Sale Orders",
                          },
                          {
                            value: counts.purchase_order_counts,
                            name: "Purchase Orders",
                          },
                          {
                            value: counts.supplier_counts,
                            name: "Total Suppliers",
                          },
                        ],
                      },
                    ],
                  }}
                  style={{ width: "100%", height: "300px" }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <Activestockpopup
        show={show}
        hideFn={hideActiveStock}
        activePrintData={activePrintData}
        inventoryData={inventoryData}
        categories={categories}
        isLoading={isLoading}
        data={{
          list: inventoryData,
          counts: inventoryCounts,
          pageno: pageNo,
          limit: limit,
          isNext: isNext,
        }}
        updateFn={paginationUpdate}
      />

      <Fruitmasterpopup
        show={fruit}
        hideFn={hideMasterActiveStock}
        isLoading={isLoading}
      />
      <Runratepopup
        show={runrate}
        date={runDate}
        changeDate={runRateDatehandle}
        categorylist={categories}
        filterData={getRunrateData}
        runrates={runRateData}
        hideFn={hideRunrate}
        isLoading={isLoading}
        totalRunrate={totalRunrate}
      />
      <Salegridpopup
        show={salegrid}
        date={saleDate}
        useByDate={useByDate}
        locationpallets={locationpallets}
        totalpallets={totalpallets}
        filterData={getSaleGridData}
        producttypes={saleProductTypes}
        zippdf={zipSaleGridData}
        locations={saleGridLocationData}
        salegrids={saleGridData}
        hideFn={hideSaleGrid}
        isLoading={isLoading}
      />
    </DefaultLayout>
    );
};
export default Dashboard;